import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { numericFormatter } from 'react-number-format';

import { Paper, Grid, Typography, Tooltip } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Filter from 'components/Filter/components';
import FilterMobile from 'components/FilterMobile/components';
import Select from 'components/Select';
import Chip from 'components/Chip';
import Button from 'components/Button';
import ModalDrawerSwitcher from 'components/ModalDrawerSwitcher';
import MTable from 'components/MTable';

import StorageMovement from 'components/StorageMovement';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSlidersH, faClockRotateLeft, faPen } from '@fortawesome/free-solid-svg-icons';

import { setProductId, setProductUuid, clearProduct, clearProductConsumption } from '../../ProductsRegistration/store/actions';
import { setProductSellUuid, clearProductSell } from '../../ProductsSellRegistration/store/actions';
import { setProductConsumeAndSellUuid, clearProductConsumeAndSell } from '../../ProductsUseAndSellRegistration/store/actions';
import { loadProductsFilters, loadProductsList, loadProductsExport } from '../store/thunk';
import { loadFilters } from 'components/Filter/store/thunk';

import { inputs } from './resources';

import useDidMount from 'helpers/hooks/useDidMount';

const iconStore = require('../../../assets/img/icon_product_store.svg');
const iconSell = require('../../../assets/img/icon_product_sell.svg');
const iconFoods = require('../../../assets/img/icon_product_foods.svg');
const iconOthers = require('../../../assets/img/icon_product_others.svg');
const iconAutomotiveParts = require('../../../assets/img/icon_product_automotive_parts.svg');

const headersConsume = [
  { title: 'Nome', field: 'name' },
  { title: 'Categoria', field: 'productTypeName' },
  { title: 'Marca', field: 'brandName' },
  { title: 'Estoque', field: 'quantityToUseColumn' }
];

const headersSell = [
  { title: 'Nome', field: 'name' },
  { title: 'Categoria', field: 'productTypeName' },
  { title: 'Marca', field: 'brandName' },
  { title: 'Estoque', field: 'quantityToSellColumn' }
];

export default function Products() {
  const dispatch = useDispatch();

  const history = useHistory();

  const didComponentMount = useDidMount();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { establishments } = useSelector(state => state.login);
  const { qp } = useSelector(state => state.filters);
  const { measurementUnits } = useSelector(state => state.products.filters);

  const {
    isLoading,
    filters,
    isConsumeList,
    isSellList,
    list: {
      content,
      total
    }
  } = useSelector(state => state.products);

  const firstPremiumPackageEstablishment = establishments
    ?.filter(establishment => +establishment?.premiumPackage == 1)[0]
    ?.establishmentId;

  const productTypesParentsId = filters?.productTypes
    ?.map(product => product.parentProductTypeId)
    ?.filter((product, index, array) => array?.indexOf(product) == index);

  const productTypesParents = filters?.productTypes
    ?.filter(product => productTypesParentsId?.includes(product?.id));

  const formattedProductType = filters?.productTypes
    ?.map(type => {
      return {
        parent: productTypesParents?.find(product => +product?.id == +type?.parentProductTypeId)?.description,
        value: type?.id,
        label: type?.description
      }
    })
    ?.filter(type => !!type.parent);

  const formattedFilters = inputs
    ?.concat({
      advanced: false,
      type: 'autocomplete',
      id: 'brandId',
      label: 'Marca',
      value: '',
      options: [
        {
          label: '--',
          value: ''
        },
        ...!!filters?.productBrands && filters?.productBrands?.map(brand => {
          return {
            label: brand?.name,
            value: brand?.id
          }
        })
      ]
    })
    ?.concat({
      advanced: false,
      type: 'autocomplete',
      id: 'productTypeId',
      label: 'Categoria',
      value: '',
      options: [
        {
          label: '--',
          value: ''
        },
        ...formattedProductType
      ]
    });

  const productTypes = [
    {
      id: 'sell',
      title: 'Produto apenas para venda',
      description: 'Produto que possui a finalidade apenas para venda.',
      icon: iconStore,
      onClick: () => history.push('/novo-produto-venda')
    },
    {
      id: 'consume',
      title: 'Produto apenas para consumo',
      description: 'Se você vai usar este produto apenas para consumo interno nas prestações de serviço.',
      icon: iconSell,
      onClick: () => history.push('/novo-produto')
    },
    {
      id: 'sellAndConsume',
      title: 'Produto para venda e consumo interno',
      description: 'Se o mesmo produto for para venda aos clientes e consumo interno através dos serviços prestados.',
      icon: '',
      onClick: () => history.push('/novo-produto-consumo-e-venda')
    },
    {
      id: 'foods',
      title: 'Alimentos',
      description: 'Alimentos que serão vendidos dentro do estabelecimento.',
      icon: iconFoods,
      onClick: () => history.push('/novo-produto-venda')
    },
    {
      id: 'others',
      title: 'Outros',
      description: 'Diversos produtos sem categoria definida.',
      icon: iconOthers,
      onClick: () => history.push('/novo-produto-venda')
    },
    {
      id: 'automotiveParts',
      title: 'Peças automotivas',
      description: 'Peças de veículos que são vendidas separadas ou dentro da ordem de serviço.',
      icon: iconAutomotiveParts,
      onClick: () => history.push('/novo-produto-venda')
    }
  ];

  const productTypesSelection = productTypes?.map(type => {
    return {
      label: type.title,
      value: type.id
    }
  });

  const [productsPage, setProductsPage] = useState(1);
  const productsPageSize = 10;
  const productsPageCount = Math.ceil(total / productsPageSize);

  const [isProductSelectDrawerOpen, setIsProductSelectDrawerOpen] = useState(false);
  const [isStorageMovementModalOpen, setIsStorageMovementModalOpen] = useState(false);
  const [isStorageMovementSell, setIsStorageMovementSell] = useState(false);
  const [isStorageMovementConsume, setIsStorageMovementConsume] = useState(false);
  const [storageMovementModalData, setStorageMovementModalData] = useState({});

  const [selectedProductType, setSelectedProductType] = useState('sell');

  const getProductType = () => {
    if(isConsumeList) {
      return 'toUse';
    }

    if(isSellList) {
      return 'toSell';
    }
  }

  const handleLoadProductsExport = () => {
    const queryParams = qp?.concat(`&type=${getProductType()}`);
    return dispatch(loadProductsExport({ userId, establishmentId, qp: queryParams }));
  }

  const requestProductsList = ({ establishmentId, page, qp }) => {
    const queryParams = qp?.concat(`&type=${getProductType()}`);
    dispatch(loadProductsList({ userId, establishmentId, page, qp: queryParams }));
  }

  useEffect(() => {
    if(!establishmentId || (+firstPremiumPackageEstablishment == +establishmentId)) {
      requestProductsList({ establishmentId: firstPremiumPackageEstablishment, page: 1, qp });
      dispatch(loadProductsFilters(userId, firstPremiumPackageEstablishment));
      return;
    }

    if(+firstPremiumPackageEstablishment != +establishmentId) {
      requestProductsList({ establishmentId, page: 1, qp });
      dispatch(loadProductsFilters(userId, establishmentId));
      return;
    }
  }, [firstPremiumPackageEstablishment]);

  useEffect(() => {
    if(establishmentId && !didComponentMount) {
      dispatch(loadFilters(userId, establishmentId));
      dispatch(loadProductsFilters(userId, establishmentId));
      requestProductsList({ establishmentId, page: productsPage, qp });
    }
  }, [establishmentId]);

  useEffect(() => {
    if(!didComponentMount) {
      requestProductsList({ establishmentId, page: productsPage, qp });
    }
  }, [productsPage]);

  useEffect(() => {
    if(!didComponentMount) {
      requestProductsList({ establishmentId, page: productsPage, qp });
    }
  }, [isConsumeList, isSellList]);

  const getQuantityStyle = (quantity, minQuantity) => {
    const quantityPercentage = +minQuantity * 100 / +quantity;

    if(quantityPercentage >= 80 || quantityPercentage < 0) {
      return {
        backgroundColor: 'rgba(246, 108, 107, 0.2)',
        color: '#F66C6B'
      }
    }

    if(quantityPercentage >= 50 && quantityPercentage < 80) {
      return {
        backgroundColor: 'rgba(245, 185, 65, 0.2)',
        color: '#F5B941'
      }
    }

    if(quantityPercentage < 50) {
      return {
        backgroundColor: 'rgba(19, 188, 75, 0.2)',
        color: '#43A047'
      }
    }

    if(!quantity || !minQuantity) {
      return {
        backgroundColor: 'rgba(19, 188, 75, 0.2)',
        color: '#43A047'
      }
    }
  }

  const formatQuantityToUse = ({ measurementUnitId, quantityToUse, minQuantityToUse }) => {
    const { abbreviation } = measurementUnits?.find(unit => unit?.id == measurementUnitId) || {};

    if(!!quantityToUse) {
      return(
        <Tooltip
          placement='top'
          title={
            <Typography>
              Atual: {numericFormatter(!!quantityToUse ? quantityToUse?.toString() : '', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })} {abbreviation}
              <br />
              Min: {numericFormatter(minQuantityToUse?.toString() || '0', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })} {abbreviation}
            </Typography>
          }
        >
          <Typography>
            <Chip
              label={numericFormatter(quantityToUse?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}
              style={getQuantityStyle(quantityToUse, minQuantityToUse)}
            />
          </Typography>
        </Tooltip>
      );
    }

    return '';
  }

  const formatQuantityToSell = ({ measurementUnitId, quantityToSell, minQuantityToSell }) => {
    const { abbreviation } = measurementUnits?.find(unit => unit?.id == measurementUnitId) || {};

    if(Number.isInteger(quantityToSell)) {
      return(
        <Tooltip
          placement='top'
          title={
            <Typography>
              Atual: {numericFormatter(quantityToSell ? quantityToSell?.toString() : '', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })} {abbreviation}
              <br />
              Min: {numericFormatter(minQuantityToSell?.toString() || '', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })} {abbreviation}
            </Typography>
          }
        >
          <Typography>
            <Chip
              label={parseInt(quantityToSell)}
              style={getQuantityStyle(quantityToSell, minQuantityToSell)}
            />
          </Typography>
        </Tooltip>
      );
    }

    return '';
  }

  return(
    <Paper>
      {isProductSelectDrawerOpen && (
        <ModalDrawerSwitcher
          open={isProductSelectDrawerOpen}
          onClose={() => setIsProductSelectDrawerOpen(false)}
          maxWidth='sm'
          title='Cadastrar produto'
          id='product-register-drawer'
        >
          <Grid container>
            <Grid xs={12}>
              <Typography color='primary' style={{ borderBottom: '1px solid #E4E7EA' }}>
                <b>Tipo de produto selecionado</b>
              </Typography>
            </Grid>
            <Grid xs={12} style={{ marginTop: 10 }}>
              <Select
                id='product-selection'
                name='product-selection'
                options={productTypesSelection}
                value={selectedProductType}
                onChange={event => setSelectedProductType(event.target.value)}
              />
            </Grid>
            {productTypes.filter(product => product.id == selectedProductType).map(product => {
              return(
                <Grid xs={12} style={{ backgroundColor: '#FCFCFC', border: '1px solid #D7D7D7', borderRadius: 5, padding: 10, marginTop: 10 }}>
                  <Grid container>
                    <Grid xs={12} style={{ borderBottom: '1px solid #E4E7EA', paddingBottom: 10, marginBottom: 10 }}>
                      <Grid container>
                        <Grid xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {product.id == 'sellAndConsume' ? (
                            <>
                              <img src={iconStore} />
                              <Typography color='primary' style={{ margin: '0 5px 0 5px' }}>
                                <b>+</b>
                              </Typography>
                              <img src={iconSell} style={{ marginRight: 10 }} />
                            </>
                          ) : (
                            <img src={product.icon} />
                          )}
                        </Grid>
                        <Grid xs={10}>
                          <Typography color='textSecondary'>
                            <b>{product.title}</b>
                          </Typography>
                          <Typography>{product.description}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        color='primary'
                        onClick={() => {
                          dispatch(clearProduct());
                          dispatch(clearProductConsumption());
                          return product.onClick();
                        }}
                      >
                        Selecionar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </ModalDrawerSwitcher>
      )}
      {isStorageMovementModalOpen && (
        <StorageMovement
          isStorageMovementModalOpen={isStorageMovementModalOpen}
          setIsStorageMovementModalOpen={setIsStorageMovementModalOpen}
          productName={storageMovementModalData?.name}
          storageProductUuid={storageMovementModalData?.storageProductUuid}
          isStorageMovementSell={isStorageMovementSell}
          isStorageMovementConsume={isStorageMovementConsume}
          pageOrigin='/produtos'
          productListCurrentPage={productsPage}
        />
      )}
      {isMobile ? (
        <FilterMobile
          filter
          filters={formattedFilters}
          isDisabledPdfExport
          alternativeExport
          functionAlternativeExport={handleLoadProductsExport}
          exportType='products'
          hasProductsButton
          actionButton={
            <div style={{ display: 'flex', gap: 5 }}>
              <Button
                type="button"
                color="primary"
                startIcon={
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    color="#FFFFFF"
                    size="xs"
                  />
                }
                onClick={() => setIsProductSelectDrawerOpen(true)}
              >
                Adicionar Produto
              </Button>
              <Button
                color="primary"
                onClick={() => history.push('/categorias-de-produtos')}
              >
                Categorias
              </Button>
              <Button
                color="primary"
                onClick={() => history.push('/marcas-de-produtos')}
              >
                Marcas
              </Button>
            </div>
          }
          handlePageRequest={({ qp }) => {
            setProductsPage(1);
            return requestProductsList({ establishmentId, page: 1, qp });
          }}
        />
      ) : (
        <Filter
          filter
          filters={formattedFilters}
          isDisabledPdfExport
          alternativeExport
          functionAlternativeExport={handleLoadProductsExport}
          exportType='products'
          hasProductsButton
          actionButton={
            <div style={{ display: 'flex', gap: 5 }}>
              <Button
                type="button"
                color="primary"
                startIcon={
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    color="#FFFFFF"
                    size="xs"
                  />
                }
                onClick={() => setIsProductSelectDrawerOpen(true)}
              >
                Adicionar Produto
              </Button>
              <Button
                color="primary"
                onClick={() => history.push('/categorias-de-produtos')}
              >
                Categorias
              </Button>
              <Button
                color="primary"
                onClick={() => history.push('/marcas-de-produtos')}
              >
                Marcas
              </Button>
            </div>
          }
          handlePageRequest={({ qp }) => {
            setProductsPage(1);
            return requestProductsList({ establishmentId, page: 1, qp });
          }}
        />
      )}
      <MTable
        loading={isLoading}
        headers={isConsumeList ? headersConsume : headersSell}
        data={content?.map(item => {
          return {
            ...item,
            quantityToUseColumn: formatQuantityToUse(item),
            quantityToSellColumn: formatQuantityToSell(item)
          }
        })}
        actions={[
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faSlidersH}
                color="#022A5C"
                size="xs"
              />
            ),
            tooltip: 'Movimentar Estoque',
            onClick: (_, rowData) => {
              const isSell = rowData?.quantityToSell != null && Number.isInteger(rowData?.quantityToSell);
              const isConsume = rowData?.quantityToUse != null && !!rowData?.quantityToUse;

              setIsStorageMovementSell(isSell);
              setIsStorageMovementConsume(isConsume);
              setStorageMovementModalData(rowData);
              setIsStorageMovementModalOpen(true);
            }
          }),
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faClockRotateLeft}
                color="#022A5C"
                size="xs"
              />
            ),
            tooltip: 'Histórico de Movimentação de Estoque',
            onClick: (_, rowData) => {
              const isSell = rowData?.quantityToSell != null && Number.isInteger(rowData?.quantityToSell);
              const isConsume = rowData?.quantityToUse != null && !!rowData?.quantityToUse;
              const isConsumeAndIsSell = isSell && isConsume;

              delete rowData['quantityToUseColumn'];
              delete rowData['quantityToSellColumn'];

              return history.push({
                pathname: '/movimentacao-de-estoque',
                state: {
                  data: {
                    product: rowData,
                    isSell,
                    isConsume,
                    isConsumeAndIsSell
                  }
                }
              });
            }
          }),
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faPen}
                color="#022A5C"
                size="xs"
              />
            ),
            tooltip: 'Editar Produto',
            onClick: (_, rowData) => {
              const isSell = rowData?.quantityToSell != null && Number.isInteger(rowData?.quantityToSell);
              const isConsume = rowData?.quantityToUse != null && !!rowData?.quantityToUse;
              const isConsumeAndIsSell = isSell && isConsume;

              if(isConsumeAndIsSell) {
                dispatch(clearProductConsumeAndSell());
                dispatch(setProductConsumeAndSellUuid(rowData?.storageProductUuid));

                return history.push('/editar-produto-consumo-e-venda');
              }

              if(isSell) {
                dispatch(clearProductSell());
                dispatch(setProductSellUuid(rowData?.storageProductUuid));

                return history.push('/editar-produto-venda');
              }

              if(isConsume) {
                dispatch(clearProduct());
                dispatch(setProductId(rowData?.id));
                dispatch(setProductUuid(rowData?.storageProductUuid));

                return history.push('/editar-produto');
              }
            }
          })
        ]}
      />
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={productsPageCount}
          page={productsPage}
          onChange={(_, page) => setProductsPage(page)}
        />
      </Grid>
    </Paper>
  );
}